@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
    }
  }

:root {
    --swiper-navigation-color: #233142;
}

.swiper-button-next,
.swiper-button-prev {
    width: 50px !important;
    height: 50px !important;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
    background-color: rgba(255, 255, 255, 0.5) !important;
}


.district-swiper-slide {
    width: 300px !important;
    height: 320px !important;
  
  }
  
.district-swiper-large-image {
    height: 300px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.district-swiper-small-image {
    height: 150px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-slide {
  position: relative;
  aspect-ratio: 4 / 3;
  container-type: size;
}
.video-slide::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 14cqh);
    clip-path: polygon(0% 0%, 0% 100%, 44% 100%, 44% 49%, 56% 49%, 56% 67%, 0% 67%, 0% 100%, 100% 100%, 100% 0%);
}

.home-title {
  background: linear-gradient(225deg, #ffffff, #dedeff, #dedeff, #7d7d86);
  background-size: 200% 200%;
  animation: rainbow 15s ease infinite;
  background-attachment: fixed;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  z-index: 2;
}

.home-title:before {
  content: '';
  z-index: 3;
  height: 30px;
  width: 300px;
  color: white;
  left: 30px;
  position: absolute;
  left: 0px;
}

@keyframes rainbow {
  0% {
      background-position: 0% 50%;
  }

  50% {
      background-position: 100% 50%;
  }

  100% {
      background-position: 0% 50%;
  }
}